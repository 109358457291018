<template>
  <el-dialog
    v-model="model"
    width="30%"
    align-center
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-openApi-integration-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-openApi-integration-dialog-header__title">
        {{ t('title.openAPIIntegration') }}
      </h4>
    </template>
    <div v-loading="loading" class="elv-openApi-integration-dialog-content">
      <div class="elv-openApi-integration-dialog-content-header">
        <p>
          {{ t('common.totalKeys') }}: <span>{{ openApiAccessList.length }}</span>
        </p>
        <div class="elv-openApi-integration-dialog-content-header__operation">
          <div class="elv-openApi-integration-operation__document" @click="onJumpDocs">
            <svgIcon name="document" width="14" height="14" fill="#838D95" />{{ t('button.APIDocument') }}
          </div>
        </div>
      </div>

      <el-table
        ref="tableListRef"
        :data="openApiAccessList"
        border
        stripe
        class="elv-openApi-integration-table"
        header-cell-class-name="elv-openApi-integration-table-header__cell"
        header-row-class-name="elv-openApi-integration-table-header"
        row-class-name="elv-openApi-integration-table-row"
        cell-class-name="elv-openApi-integration-table-row__cell"
      >
        <el-table-column :label="t('common.name')">
          <template #default="{ row }">
            <TextCell :text="row.name" font-family="Plus Jakarta Sans" />
          </template>
        </el-table-column>
        <el-table-column :label="t('report.key')" width="160">
          <template #default="{ row }">
            <div class="elv-openApi-integration-table-row__cell-key">
              {{ middleEllipsis(row.apiKey, 6) }}
              <img src="@/assets/img/copy.png" alt="copy" @click="onClickedCopy(row.apiKey)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.secret')" width="160">
          <template #default="{ row }">
            <div class="elv-openApi-integration-table-row__cell-key">
              {{ middleEllipsis(row.apiSecret, 6) }}
              <img src="@/assets/img/copy.png" alt="copy" @click="onClickedCopy(row.apiSecret)" />
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('common.created')" width="140">
          <template #default="{ row }">
            <TextCell
              :text="dayjs.tz(row.createdAt, entityStore.entityDetail?.timezone).format('YYYY/MM/DD HH:mm:ss')"
            />
          </template>
        </el-table-column>
      </el-table>

      <div class="elv-openApi-integration-dialog-content-footer">
        <SvgIcon name="help" :width="12" :height="12" :fill="'#AAAFB6'" />
        <i18n-t keypath="desc.addOpenApiPageGuideTipInfo" tag="p" scope="global">
          <template #page1>
            <span class="page-name">{{ t('common.projectSettings') }}</span>
          </template>
          <template #page2>
            <span class="page-name">{{ t('common.openAPI') }}</span>
          </template>
          <template #button>
            <span class="page-button" @click="jumpToOpenApiSetting">{{ t('button.goToManage') }}</span>
          </template>
        </i18n-t>
      </div>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import timezone from 'dayjs/plugin/timezone'
import { middleEllipsis } from '@/lib/utils'
import BusinessDataApi from '@/api/BusinessDataApi'
import { useEntityStore } from '@/stores/modules/entity'
import { OpenApiAccessType } from '#/BusinessDataTypes'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'

dayjs.extend(utc)
dayjs.extend(timezone)
const route = useRoute()
const { t, locale } = useI18n()
const { toClipboard } = useClipboard()

const entityStore = useEntityStore()
const model = defineModel<boolean>({ required: true })
const openApiAccessList = ref<OpenApiAccessType[]>([])
const loading = ref(false)

const emit = defineEmits(['onJumpToOpenApiSettingPage'])

// eslint-disable-next-line no-unused-vars
const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

// eslint-disable-next-line no-unused-vars
const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const fetchOpenApiAccessList = async () => {
  try {
    loading.value = true
    const { data } = await BusinessDataApi.getOpenApiAccessList(entityId.value)
    openApiAccessList.value = data
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

const onJumpDocs = () => {
  const url =
    locale.value === 'en'
      ? 'https://docs.elven.com/v3/openapi/obtaining-api-authorization'
      : 'https://docs.elven.com/v3/chinese/openapi/jie-kou-shou-quan'
  window.open(url, '_blank')
}

const jumpToOpenApiSetting = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.administratorAccessRequiredTip'))
    return
  }
  emit('onJumpToOpenApiSettingPage')
}

watch(
  () => model.value,
  (value: any) => {
    if (value) {
      fetchOpenApiAccessList()
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-openApi-integration-dialog {
  width: 660px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-source-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-openApi-integration-dialog-content {
      width: 100%;
    }

    .elv-openApi-integration-dialog-content-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      p {
        color: #838d95;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          margin-left: 8px;
          color: #0e0f11;
        }
      }

      .elv-openApi-integration-dialog-content-header__operation {
        display: flex;
        align-items: center;
      }

      .elv-openApi-integration-operation__document {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        svg {
          margin-right: 6px;
        }

        &:hover {
          color: #1753eb;
        }
      }
    }

    .elv-openApi-integration-table {
      --el-table-border-color: #d4dce5;
      --el-table-border: 1px solid #edf0f3;
      --el-fill-color-lighter: #f9fafb;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #d2dbe4;
      border-radius: 3px;
      border-bottom-width: 1px;

      .el-table__inner-wrapper {
        width: 100%;

        &::after,
        &::before {
          width: 0;
          height: 0;
        }
      }

      &.el-table--border {
        &::after,
        &::before {
          width: 0;
          height: 0;
        }
      }

      .el-table__body-wrapper {
        .el-scrollbar__view {
          margin-bottom: 0px !important;
        }
      }

      .el-table__border-left-patch {
        width: 0;
      }

      .elv-openApi-integration-table-header {
        background: #fff;

        .elv-openApi-integration-table-header__cell {
          background: #fff !important;
          height: 23px;
          box-sizing: border-box;
          border-right-color: #edf0f3;
          border-bottom-color: #edf0f3;
          padding: 0px;

          &:last-child {
            border-right: 0px;
          }

          &.is-center {
            .cell {
              justify-content: center;
            }
          }

          &.is-right .cell {
            justify-content: flex-end;
          }

          .cell {
            color: #b2bacc;
            font-family: 'Plus Jakarta Sans';
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-items: center;
          }
        }
      }

      .el-table__row.elv-openApi-integration-table-row:last-of-type {
        .elv-openApi-integration-table-row__cell.el-table__cell {
          border-bottom: 0px;
        }
      }

      .elv-openApi-integration-table-row__cell {
        padding: 0;
        height: 42px;
        cursor: pointer;

        &:last-child {
          border-right: 0px;
        }

        &.is-right .cell {
          text-align: right;
        }

        &.is-center .cell {
          text-align: center;
        }

        &.is-number .cell {
          font-family: 'Barlow';
          font-weight: 500;
        }

        .cell {
          padding: 0 10px;
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: #0e0f11;
          line-height: 16px;
        }

        .elv-openApi-integration-table-row__cell-key {
          display: flex;
          align-items: center;
          color: #1e2024;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          img {
            width: 10px;
            height: 10px;
            display: block;
            margin-left: 4px;
            cursor: pointer;
          }
        }

        .elv-valuation-record-cell__status {
          display: flex;
          height: 22px;
          width: fit-content;
          padding: 0px 8px;
          box-sizing: border-box;
          align-items: center;
          gap: 4px;
          border-radius: 14px;
          border: 1px solid #d0d4d9;
          background: #edf0f3;
          color: #636b75;

          svg {
            margin-right: 4px;
          }

          &.is-approved {
            border-color: #a9dc78;
            background: #f3fceb;
            color: #50970b;
          }
        }

        .elv-openApi-integration-table-row__cell-button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            cursor: pointer;
            fill: #838d95;

            &.is-disabled {
              cursor: not-allowed !important;
              fill: #edf0f3 !important;
            }

            &:first-of-type {
              margin-right: 8px;
            }

            &:hover {
              fill: #1e2024;
            }
          }
        }
      }
    }

    .elv-openApi-integration-dialog-content-footer {
      display: flex;
      align-items: center;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 12px;
      color: #636b75;
      margin-top: 12px;

      svg {
        margin-right: 4px;
      }

      .page-name {
        color: #1e2024;
      }

      .page-button {
        cursor: pointer;
        text-decoration: underline;
        color: #1753eb;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
